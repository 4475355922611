export class Address {
    constructor(
        public line1: string,
        public line2: string,
        public line3: string,
        public postalCode: string,
        public city: string,
        public state: string,
        public country: string
    ) { }

public static empty(): Address {
    return new Address('', '', '', '', '', '', '');
}

}
