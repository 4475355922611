import { Chracteristics } from './characteristic';
import { Prices } from './prices';

export class Resource {
    public static fromJson(json): Resource {
      return new Resource(
        json.id,
        json.name,
        json.description,
        json.resourceTypeId,
        json.centerId,
        json.siteId,
        json.characteristics,
        json.prices
      );
    }

    constructor(
      public id: string,
      public name: string,
      public description: string,
      public resourceTypeId: string,
      public centerId: string,
      public siteId: string,
      public characteristics: Chracteristics[],
      public prices: Prices[]
    ) { }

  }
