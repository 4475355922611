import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Activity } from '../models/activity';

@Injectable({
  providedIn: 'root'
})

export class ActivityService {

  private activitiesUrl = environment.bookingApiUrl + '/v1/activities';

  constructor(private http: HttpClient) { }

  getActivities(location: string): Observable<Activity[]> {
    return this.http.get<Activity[]>(`${this.activitiesUrl}?location=` + location);
  }
  getActivitiesByCity(city: string): Observable<Activity[]> {
    return this.http.get<Activity[]>(`${this.activitiesUrl}?city=` + city);
  }
  getActivitiesByCountry(countryCode: string): Observable<Activity[]> {
    return this.http.get<Activity[]>(`${this.activitiesUrl}?countryCode=` + countryCode.toUpperCase());
  }
  getActivitiesByCenter(centerId: string): Observable<Activity[]> {
    return this.http.get<Activity[]>(`${this.activitiesUrl}?centerId=` + centerId);
  }
}
