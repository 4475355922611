import { Address } from './address';
import { LocationObject } from './location';

export class Sites {
    public static fromJson(json): Sites {
      return new Sites(
        json.address ? new Address(
            json.address.line1,
            json.address.line2,
            json.address.line3,
            json.address.postalCode,
            json.address.city,
            json.address.state,
            json.address.country) : Address.empty(),
        json.id,
        json.location ? new LocationObject(json.location.lat, json.location.lon) : LocationObject.empty(),
        json.name,
      );
    }

    constructor(
      public address: Address,
      public id: string,
      public location: LocationObject,
      public name: string,
    ) { }
  }
