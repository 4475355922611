export class Activity {
  public static fromJson(json): Activity {
    return new Activity(
      json.id,
      json.bookingName,
      json.bookingPriceCents,
      json.currencyCode,
      json.currencySymbol,
      json.discountPriceCents,
      json.facilityId,
      json.facilityName,
      json.facilitySurface,
      json.facilitySurfaceType,
      json.fencing,
      json.heated,
      json.isIndoor,
      json.light,
      json.name,
      json.partySize,
      json.payForOnePerson,
      json.practiceWall,
      json.priceCents,
      json.pricePerPersonCents,
      json.typeName,
      json.icon,
    );
  }

  constructor(
    public id: string,
    public bookingName: string,
    public bookingPriceCents: number,
    public currencyCode: string,
    public currencySymbol: string,
    public discountPriceCents: number,
    public facilityId: string,
    public facilityName: string,
    public facilitySurface: string,
    public facilitySurfaceType: string,
    public fencing: string,
    public heated: boolean,
    public isIndoor: boolean,
    public light: boolean,
    public name: string,
    public partySize: number,
    public payForOnePerson: boolean,
    public practiceWall: boolean,
    public priceCents: number,
    public pricePerPersonCents: number,
    public typeName: string,
    public icon: string,
  ) { }

}
