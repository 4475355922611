import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Credentials } from '../../marketplace/models/credentials';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public role = 'pro';
  private loginUrl: string = environment.organiserApiUrl + '/v1/auth';
  private headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) {}

  authWithProvider(
    provider: string,
    token?: string,
    credentials?: Credentials
  ): Observable<any> {
    const json = { provider };
    if (token) {
      json['token'] = token;
    }
    if (credentials) {
      json['credentials'] = credentials;
    }
    return this.http.post(`${this.loginUrl}`, JSON.stringify(json), {
      headers: this.headers,
      observe: 'response'
    });
  }
}
