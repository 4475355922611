export class ResourceTypes {
    public static fromJson(json): ResourceTypes {
      return new ResourceTypes(
        json.id,
        json.name,
        json.activityId,
        json.characteristics,
      );
    }

    constructor(
      public id: string,
      public name: string,
      public activityId: string,
      public characteristics: string,
    ) { }

  }
