export class Amenity {
    public static fromJson(json: object): Amenity {
      const code = 'code';
      const name = 'name';
      return new Amenity(

        json[code],
        json[name]
      );
    }

    constructor(
      public code: string,
      public name: string
    ) { }
  }
