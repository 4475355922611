import { Center } from './center';
import { Paging } from './paging';

export class Availability {

  public static fromJson(json): Availability {

    return new Availability(
      json.availabilities.centers.map(center => Center.fromJson(center)),
      json.availabilities.date,
      json.availabilities.partySize,
      json.availabilities.texts,
    );
  }

  constructor(
    public centers: Center[],
    public date: string,
    public partySize: number,
    public texts: string[],
  ) { }

}

export class Availabilities {

  public static fromJson(json): Availabilities {
    return new Availabilities(
      json.data.map(center => Center.fromJson(center)),
      Paging.fromJson(json.paging),
    );
  }

  constructor(
    public data: Center[],
    public paging: Paging
  ) { }

}
