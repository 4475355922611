import { Address } from './address';
import { Amenity } from './amenities';
import { Activity } from './activity';
import { Service } from './service';
import { Resource } from './resource';
import { Staff } from './staff';
import { ServiceTypes } from './service-types';
import { ResourceTypes } from './resource-types';
import { LocationObject } from './location';
import { Sites } from './sites';
import { OpeningHours } from './opening-hours';

export class Center {

  public static fromJson(json): Center {
    return new Center(
      json.id,
      json.name,
      json.formattedAddress,
      json.neighborhoodName,
      json.phoneNumber,
      json.email,
      json.address ? new Address(
        json.address.line1,
        json.address.line2,
        json.address.line3,
        json.address.postalCode,
        json.address.city,
        json.address.state,
        json.address.country) : Address.empty(),
      json.currency,
      json.website,
      json.headerPhoto,
      json.profilePhoto,
      json.location ? new LocationObject(json.location.lat, json.location.lon) : LocationObject.empty(),
      json.timezone,
      json.instantBook,
      json.metaTitle,
      json.metaDescription,
      json.shortDescription,
      json.longDescription,
      json.rating,
      json.ratingCount,
      json.state,
      json.isDeleted,
      json.currenciesAccepted,
      json.settings,
      json.amenities ? json.amenities.map(amenity => Amenity.fromJson(amenity)) : [],
      json.activities ? json.activities.map(activity => Activity.fromJson(activity)) : [],
      json.services ? json.services.map(service => Service.fromJson(service)) : [],
      json.resources ? json.resources.map(resource => Resource.fromJson(resource)) : [],
      json.staffs ? json.staffs.map(staff => Staff.fromJson(staff)) : [],
      json.serviceTypes ? json.serviceTypes.map(serv => ServiceTypes.fromJson(serv)) : [],
      json.resourceTypes ? json.resourceTypes.map(res => ResourceTypes.fromJson(res)) : [],
      json.sites ? json.sites.map(res => Sites.fromJson(res)) : [],
      json.bookingRules,
      json.importantMessage,
      json.bookingType,
      json.isFullAnybuddy,
      json.timeSlots,
      json.distance,
      json.isPartner,
      json.openingHours,
      json.isCertified,
      json.cancellationPolicy,
      json.averageResponseTimeString,
      json.rulesUrl
    );
  }

  constructor(
    public id: string,
    public name: string,
    public formattedAddress: string,
    public neighborhoodName: string,
    public phoneNumber: string,
    public email: string,
    public address: Address,
    public currency: string,
    public website: string,
    public headerPhoto: string[],
    public profilePhoto: string[],
    public location: LocationObject,
    public timezone: string,
    public instantBook: boolean,
    public metaTitle: string,
    public metaDescription: string,
    public shortDescription: string,
    public longDescription: string,
    public rating: number,
    public ratingCount: number,
    public state: string,
    public isDeleted: boolean,
    public currenciesAccepted: string,
    public settings: string,
    public amenities: Amenity[],
    public activities: Activity[],
    public services: Service[],
    public resources: Resource[],
    public staffs: Staff[],
    public serviceTypes: ServiceTypes[],
    public resourceTypes: ResourceTypes[],
    public sites: Sites[],
    public bookingRules: string,
    public importantMessage: string,
    public bookingType: string,
    public isFullAnybuddy: boolean,
    public timeSlots: any,
    public distance: number,
    public isPartner: boolean = false,
    public openingHours?: OpeningHours,
    public isCertified?: boolean,
    public cancellationPolicy?: any,
    public averageResponseTimeString?: string,
    public rulesUrl?: string
  ) { }

  public getPath(): string {
    const countryPrefix = this.address.country.toLowerCase() === 'fr' ? '' : this.address.country.toLowerCase() + '/';
    return countryPrefix + 'club-' + this.id;
  }
}
