import { Places } from './places';

export class InfoPaiement {
  // id: string;
  centerId: string;
  slotId: string;
  serviceId: string;
  price: number;
  places: Places[];
}
