export class LocationObject {
    lat: string;
    lon: string;
    constructor(
        lat: string,
        lon: string,
    ) {
        this.lat = lat,
        this.lon = lon;
    }

    public static empty(): LocationObject {
        return new LocationObject('', '');
    }
}
