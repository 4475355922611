import { Chracteristics } from './characteristic';
import { Prices } from './prices';
/* eslint-disable */
export class ServiceInformation {
  public static fromJson(json): ServiceInformation {
    return new ServiceInformation();
  }
  id?: string;
  name?: string;
  price?: number;
  currency?: string;
  charachteristic?: Chracteristics[];
  prices?: Prices[];
  userPrices?: Prices[];
  startTime?: string;
  duration?: number;
  photo?: string;
  availablePlaces?: number;
  totalCapacity?: number;
  description?: string;
  nbPlaces?: number;
  placePrise?: number;
  discountPrice?: number;
  isCancellable?: boolean;
  serviceFee?: number;
  serviceFeeText?: string;
  slotId?: string;
  startDateTime?: string;
  cancellationCondition?: string;
  freeCancellationHours?: number;
  isCancellableNow?: boolean;

  constructor() {}
}


export class ServicesSlotsInformation {
  public startDateTime: string;
  public services: ServiceInformation[];
  public static fromJson(json): ServicesSlotsInformation {
    return new ServicesSlotsInformation();
  }

  constructor() { }
}
