import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
/* eslint-disable */
@Injectable({
  providedIn: 'root'
})
export class TagManagerService {
  constructor(private tagManager: GoogleTagManagerService) {}

  pushTag(data: Object): void {
    this.tagManager.pushTag(data);
  }

  pushScreenView(data: Object): void {
    this.pushTag({
      event: 'screen_view',
      screen_view_data: data
    });
  }

  pushLogin(userId: string): void {
    this.pushTag({
      event: 'login',
      login_data: userId
    });
  }

  pushLogout(data: object): void {
    this.pushTag({
      event: 'logout',
      logout_data: data
    });
  }

  pushSelectClub(data: object): void {
    this.pushTag({
      event: 'select_club',
      club_data: data
    });
  }

  pushSelectSlot(data: object): void {
    this.pushTag({
      event: 'select_slot',
      select_slot_data: data
    });
  }

  pushPaymentInitiated(data: object): void {
    this.pushTag({
      event: 'payment_initiated',
      payment_initiated_data: data
    });
  }

  pushAbortPayment(data: object): void {
    this.pushTag({
      event: 'abort_payment',
      abort_payment_data: data
    });
  }

  pushCheckout(data: object): void {
    this.pushTag({
      event: 'checkout',
      checkout_data: data
    });
  }

  pushCheckoutFailed(data: object): void {
    this.pushTag({
      event: 'checkout_failed',
      checkout_failed_data: data
    });
  }

  pushAddToWishList(data: object): void {
    this.pushTag({
      event: 'add_to_wishlist',
      add_to_wishlist_data: data
    });
  }

  pushRemoveToWishList(data: object): void {
    this.pushTag({
      event: 'remove_to_wishlist',
      remove_to_wishlist_data: data
    });
  }

  pushDeleteAccount(data: object): void {
    this.pushTag({
      event: 'delete_account',
      delete_account_data: data
    });
  }

  pushMyProfile(data: object): void {
    this.pushTag({
      event: 'my_profile',
      my_profile_data: data
    });
  }

  pushReservationDetails(data: object): void {
    this.pushTag({
      event: 'reservation_details',
      reservation_details_data: data
    });
  }

  pushDownloadApp(): void {
    this.pushTag({
      event: 'download_app'
    });
  }
  pushSelectSport(data: object): void {
    this.pushTag({
      event: 'sport_selected',
      sport_selected_data: data
    });
  }

  pushSearchCity(data: object): void {
    this.pushTag({
      event: 'city_search',
      city_search_data: data
    });
  }

  pushRegister(userId: string, userName: string, userMail: string): void {
    this.pushTag({
      event: 'register',
      distinct_id: userId,
      update_name: userName,
      update_email: userMail
    });
  }

  pushUpdateProfile(userName: string, userMail: string): void {
    this.pushTag({
      event: 'update_profile',
      update_name: userName,
      update_email: userMail
    });
  }

  pushHome(): void {
    this.pushTag({
      event: 'home'
    });
  }

  pushBeginCheckout(data: object): void {
    this.pushTag({
      event: 'begin_checkout',
      begin_checkout_data: data
    });
  }

  pushPurchaseGA(data: Object): void {
    this.pushTag({
      event: 'purchase_ga',
      purchase_ga_data: data
    });
  }

  pushPurchaseFB(data: Object, eventId: string): void {
    this.pushTag({
      event: 'purchase_fb',
      purchase_fb_data: data,
      event_id: eventId
    });
  }

  pushIdentify(userId: string, userName: string, userMail: string) {
    this.pushTag({
      event: 'identify',
      distinct_id: userId,
      update_name: userName,
      update_email: userMail
    });
  }
}
