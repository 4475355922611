/* eslint-disable */
export class Staff {
    public static fromJson(json): Staff {
      return new Staff(
        json.id,
        json.fisrtName,
        json.lastName,
        json.profilePhoto
      );
    }

    constructor(
      public id: string,
      public fisrtName: string,
      public lasttName: string,
      public profilePhoto: string
    ) { }

  }
