import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { InfoPaiement } from '../models/infoPaiment';
import { ServiceInformation } from '../models/serviceInformation';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  private emitChangeSource = new Subject<any>();

  public changeEmitted$ = this.emitChangeSource.asObservable();
  emitChange(change: any): void {
    this.emitChangeSource.next(change);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SelectedSportService {

  private emitChangeSource = new Subject<any>();

  public sportSelected: string;
  public changeEmitted$ = this.emitChangeSource.asObservable();

  emitChange(change: any): void {
    this.emitChangeSource.next(change);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SlotService {

  private emitChangeSource = new BehaviorSubject<any>(null);

  changeEmitted$ = this.emitChangeSource.asObservable();

  emitChange(change: any): void {
    this.emitChangeSource.next(change);
  }
}
@Injectable({
  providedIn: 'root',
})
export class PriceService {

  private emitChangeSource = new Subject<any>();

  changeEmitted$ = this.emitChangeSource.asObservable();

  emitChange(change: any): void {
    this.emitChangeSource.next(change);
  }
}
@Injectable({
  providedIn: 'root',
})
export class CenterAvailabilitiesService {

  private emitChangeSource: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private searchTriggered: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  private isFromFilter: boolean;
  public changeEmitted$ = this.emitChangeSource.asObservable();
  public changeSearchEmitted$ = this.searchTriggered.asObservable();
  private data: any = [];

  emitChange(change: any): void {
    this.emitChangeSource.next(change);
    if (this.isFromFilter) {
      this.data = [];
    }
    if (change && change.paging) {
      this.data = change.data;
    } else {
      this.data = change;
    }
  }

  emitTrigerred(): void {
    this.searchTriggered.next(true);
  }

  getDataList(): void {
    return this.data;
  }

  setIsFromFilter(b: boolean): void {
    this.isFromFilter = b;
  }

  getIsFromFilter(): boolean {
    return this.isFromFilter;
  }
}

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private emitChangeSource: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  public changeEmitted = this.emitChangeSource.asObservable();

  public stateServiceData = new StateServiceData()

  public changePushed(): void {
    this.emitChangeSource.next(true);
  }

  public init(stateService: StateServiceData): void {
    this.stateServiceData.init(stateService)
  }

}

export class StateServiceData {
  servPrice = new ServiceInformation();
  initial = new ServiceInformation();
  total: number;
  nbrBooked: number;

  public init(stateService: StateServiceData): void {
    this.servPrice = stateService.servPrice;
    this.initial = stateService.initial;
    this.total = stateService.total;
    this.nbrBooked = stateService.nbrBooked;
  }
}


export class InfoPaiementService {

  servPrice = new InfoPaiement();
  get selectedValue(): InfoPaiement {
    return this.servPrice;
  }

}



