import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export function isMarketplaceDomain(): boolean {
  return environment.whitelabelId === null;
}

@Injectable({
  providedIn: 'root'
})
export class AppContextService {
  private _isWhiteLabel: boolean;

  constructor() {
    this._isWhiteLabel = !isMarketplaceDomain();
    console.log('isWhiteLabel', this._isWhiteLabel);
  }

  getisWhiteLabel(): boolean {
    return this._isWhiteLabel;
  }
}
