export declare interface LanguageData {
    code: string;
    languageCode: string;
    countryCode: string;
    name: string;
    icon: string;
    coordinates: string;
}

export declare interface CountryData {
  name: string;
  countryCode: string;
  icon: string;
  coordinates: string;
}

export declare interface NewLanguageData {
  languageCode: string;
  trueLanguageCode: string;
  countryCode: string[];
  name: string;
}


export const COUNTRY_LIST: CountryData [] = [
  { name: 'France', countryCode: 'FR', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-fr.png', coordinates: '48.8534,2.3488' },
  { name: 'Swiss', countryCode: 'CH', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-ch.png', coordinates: '46.1983922,6.1422961' },
  { name: 'Belgium', countryCode: 'BE', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-be.png', coordinates: '51.1642292,10.4541194' },
  { name: 'Spain', countryCode: 'ES', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-es.png', coordinates: '37.7167,-2.1417' },
];

export const LANGUAGE_LIST: LanguageData [] = [
    { code: 'fr-FR', languageCode: 'fr', countryCode: 'FR', name: 'French', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-fr.png', coordinates: '48.8534,2.3488' },
    { code: 'fr-CH', languageCode: 'fr', countryCode: 'CH', name: 'Swiss', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-ch.png', coordinates: '46.1983922,6.1422961' },
    { code: 'fr-BE', languageCode: 'fr', countryCode: 'BE', name: 'Belgium', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-be.png', coordinates: '51.1642292,10.4541194' },
    { code: 'nl-BE', languageCode: 'nl', countryCode: 'BE', name: 'Belgique', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-be.png', coordinates: '51.1642292,10.4541194' },
    { code: 'es-ES', languageCode: 'es', countryCode: 'ES', name: 'Spanish', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-es.png', coordinates: '37.7167,-2.1417' },
    { code: 'en-FR', languageCode: 'en', countryCode: 'FR', name: 'English', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677164953/assets/flag/flag-en.png', coordinates: '48.8534,2.3488' },
];

export const NEW_LANGUAGE_LIST: NewLanguageData [] = [
  { languageCode: 'fr', trueLanguageCode: 'fr', countryCode: ['FR'], name: 'French' },
  { languageCode: 'fr', trueLanguageCode: 'ch', countryCode: ['CH'], name: 'Swiss French' },
  { languageCode: 'fr', trueLanguageCode: 'be', countryCode: ['BE'], name: 'Belgian French' },
  { languageCode: 'nl', trueLanguageCode: 'nl', countryCode: ['BE'], name: 'Belgian'},
  { languageCode: 'es', trueLanguageCode: 'es', countryCode: ['ES'], name: 'Spanish' },
  { languageCode: 'en', trueLanguageCode: 'en', countryCode: ['FR', 'CH', 'BE', 'ES'], name: 'English' },
];
