import { Prices } from './prices';

export class Service {
    public static fromJson(json): Service {
      return new Service(
        json.id,
        json.name,
        json.isPriceResource,
        json.description,
        json.price,
        json.duration,
        json.rating,
        json.ratingCount,
        json.serviceTypeId,
        json.staffId,
        json.resourceId,
        json.externalId,
        json.photos,
        json.availablePlaces,
        json.totalCapacity,
        json.prices,
        json.activate,
        json.resourceId,
        json.serviceExternalId,
      );
    }

    constructor(
      public id: string,
      public name: string,
      public isPriceResource: boolean,
      public description: string,
      public price: number,
      public duration: number,
      public rating: number,
      public ratingCount: number,
      public serviceTypeId: string,
      public staffId: string,
      public resourceId: string,
      public externalId: string,
      public photos: string[],
      public availablePlaces: number,
      public totalCapacity: number,
      public prices: Prices[],
      public activate: boolean,
      public ressourceId: string,
      public serviceExternalId: string,
    ) { }

  }
